<template>
  <div class="intro-container">
    <transition name="fade">
      <div class="page-title main" v-if="visible" @click="next">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "Intro",
    props: {
      content: {
        type: String
      }
    },
    created() {
      this.tv = setTimeout(()=>{
        this.tv = undefined;
        this.next();
      }, 2500);
    },
    mounted() {
      setTimeout(()=>{
        this.visible = true;
      },500)
    },
    data() {
      return {
        tv: undefined,
        visible: false
      }
    },
    methods: {
      next() {
        if(this.tv) {
          clearTimeout(this.tv);
          this.visible = false;
          this.$emit('next');
        } else {
          this.visible = false;
          setTimeout(()=>{
            this.$emit('next');
          },1100);
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .intro-container
    position fixed
    top 50%
    left 50%
    transform translate(-50%, -50%)
    text-align center
  .fade-enter-active {
    transition: opacity 2s;
  }
  .fade-leave-active {
    transition: opacity 1s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
